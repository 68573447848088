import { useState } from "react";

function App() {
  const [counter, setCounter] = useState(0);


  return (
    <div className="h-screen w-screen flex bg-gray-100">
      <div className="m-auto text-6xl font-bold text-gray-600">
        <div>Joji Thama Draw</div>
        <div className="">
          <button
            className="text-center w-full"
            onClick={() => setCounter(counter + 1)} 
            >
            <div className="text-lg font-normal">Counter: {counter}</div>
          </button>

        </div>
      </div>
    </div>
  );
}

export default App;
